'use strict';
// import $ from 'jquery';
// require('endlessRiver');
// import endlessRiver from 'endlessRiver';
//const anime = require('animejs');
import anime from 'animejs/lib/anime.js';
let menulist;
let menubtn;
$(function() {
    init();
});

const init = ()=>{
    menuSetting();
    anchorSetting();
    logomotion();
    $(window).on('scroll', scrollFader);
    scrollFader();
}
const scrollFader = ()=>{
    $('.effect-scroll').each(function(){
        var elemPos = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight+300){
         $(this).addClass('effect-fade');
        }
    });
}
const logomotion = ()=>{
    anime({
        targets:"#a2",
        rotate: [-90,0],
        easing:'easeInOutCubic',
        delay: 300,
        duration: 1000,
        opacity: 1
    })
    anime({
        targets:"#h1",
        rotate: [-90,0],
        easing:'easeInOutCubic',
        delay: 600,
        duration: 1000,
        opacity: 1
    })
    anime({
        targets:"#a1",
        rotate: [-90,0],
        easing:'easeInOutCubic',
        delay: 900,
        duration: 1000,
        opacity: 1
    })
    anime({
        targets:"#org_name .g1",
        transrateX:[-30,0],
        easing:'easeInOutCubic',
        delay: 1800,
        duration: 1000,
        opacity: 1
    })
    anime({
        targets:"#org_name .g2",
        transrateX:[-30,0],
        easing:'easeInOutCubic',
        delay: 2100,
        duration: 1000,
        opacity: 1
    })
}
const anchorSetting = ()=>{
    $('a[href^="#"]').on("click",function(){
        if( $(menubtn).hasClass("open") ){
            menuClose($(menubtn))
        }
        let speed = 500;
        let href= $(this).attr("href");
        let target = $(href == "#" || href == "" ? 'html' : href);
        let position = target.offset().top;
        $("html, body").animate({scrollTop:position}, speed, "swing");
        return false;
    });
}
const menuSetting = ()=>{
    menubtn = $("#menu");
    menulist = $("#menu-container");

    $(menubtn).on("click",(e)=>{
        // console.log(e.currentTarget);
        const target = e.currentTarget;
        if( !$(target).hasClass("open") ){
            menuOpen();
        } else {
            menuClose();
        }
    });
}

const menuOpen = ()=>{
    $(menubtn).addClass("open");
    $(menulist).show();
    $(menulist).animate({opacity:1,top:"100%"},200,"swing");
}
const menuClose = ()=>{
    $(menubtn).removeClass("open");
    $(menulist).animate({opacity:0,top:"80%"},200,"swing",()=>{
        $(menulist).hide();
    });
}